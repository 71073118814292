<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <h4 class="card-title">
            Instruções
          </h4>
          <p>Selecione o tipo arquivo a ser importado, Siga as instruções corretamente e clique em 'Importar dados'.</p>

          <div v-if="selectedType === 'success_valuation'">
            <h3>Avaliação dos Consultores de Sucess via Google Forms</h3>
            <h5>Enviar um CSV com as seguintes colunas(na ordem descrita):</h5>
            <p>
              - E-mail<br>
              - Avaliação do consultor (0 a 5)<br>
              - Data da resposta (formato dd/mm/aaaa hh:mm:ss)<br>
            </p>
          </div>

          <div v-if="selectedType === 'user_lead'">
            <h3>Usuário do Tipo Lead</h3>
            <p>
              - Enviar um CSV com as seguintes colunas: nome, tipo(company ou representative), e-mail<br>
              <br>
              OU<br>
              <br>
              - Preencher o campo Lista de Usuários com o layout: nome;tipo;e-mail (separando nome, tipo e e-mail com ponto e vírgula)<br>
              - Exemplos:<br>
              Carlos Sains;representative;carlos.sains@gmail.com<br>
              Valteri Bottas;company;valteri.botas@gmail.com<br>
            </p>
          </div>

          <div v-if="selectedType === 'answered_whats_app'">
            <h3>Zap Fácil</h3>
            <p>
              - https://painel.zapfacil.com/<br>
              - Abrir o menu Atendimentos<br>
              - Filtrar por Status Finalizado<br>
              - Filtrar o período desejado (sugestão: -5 dias)<br>
              - Clicar no botão Exportar e selecionar CSV
            </p>
          </div>

          <div v-if="selectedType === 'answered_whats_app_click_massa'">
            <h3>Click Massa</h3>
            <p>
              - https://enterprise-49.clickmassa.com.br/<br>
              - Abrir o menu Dashboard<br>
              - Detalhamento do Atendimento<br>
              - Filtrar por Status Fechado<br>
              - Clicar no botão Excel<br>
              - Abrir o arquivo gerado<br>
              - Remover a coluna mensagem<br>
              - Conferir se nome dos consultores são iguais aos do Hafidme<br>
              - <srtong>Salvar em CSV</srtong>
            </p>
          </div>

          <div v-if="selectedType === 'answered_emails_help_desk'">
            <h3>FreshWorks </h3>
            <p>
              - https://hafidme.myfreshworks.com/<br>
              - Entrar em Tickets<br>
              - Filtrar por Status Resolvido<br>
              - Filtrar o período desejado (sugestão: -5 dias)<br>
              - Clicar em Exportar<br>
              - Selecionar as Colunas (Status, Analista, Hora da Resolução)<br>
              - Clicar em exportar<br>
              - Baixar o arquivo que foi enviado pelo e-mail
              <br>
            </p>
          </div>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Dados"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="5">
              <b-form-group
                label="Tipo do relatório"
                label-for="type"
              >
                <b-form-select
                  id="type"
                  v-model="selectedType"
                  :options="selectType"
                  :disabled="isLoading"
                  @change="selectedChanged"
                />
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group
                label="Arquivo"
                label-for="file"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="file"
                  :disabled="selectedType == ''"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="selectedType === 'user_lead'"
              cols="10"
            >
              <b-form-group
                label="Usuários"
                label-for="text"
              >
                <b-form-textarea
                  id="text"
                  ref="text"
                  v-model="text"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="10"
              class="form-buttons text-right"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-validate"
                  :disabled="isLoading"
                  variant="primary"
                  @click="sendFile()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Importar dados
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ImportService from '@/services/importService'
import { formatDateTimeDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BSpinner,
  BButton,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import toast from '@/mixins/toast'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BButton,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BBreadcrumb,
  },

  mixins: [requestErrorMessage, toast],

  data() {
    return {
      importService: null,
      isLoading: false,
      selectedType: '',
      selectType: [
        {
          value: 'answered_whats_app',
          text: 'Whats App (ZapFácil)',
        },
        {
          value: 'answered_whats_app_click_massa',
          text: 'Whats App (Click Massa)',
        },
        {
          value: 'answered_emails_help_desk',
          text: 'HelpDesk (Freshworks)',
        },
        {
          value: 'user_lead',
          text: 'Usuário do tipo Lead',
        },
        {
          value: 'success_valuation',
          text: 'Avaliação dos Consultores de Sucesso',
        },
      ],
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Importação de Dados',
          active: true,
        },
      ],

      file: [],
      text: '',
      formatDateTimeDbToView,
    }
  },

  created() {
    this.importService = new ImportService()
  },

  methods: {
    selectedChanged() {
      this.file = null
    },

    sendFile() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('text', this.text)

      this.importService.sendFile(this.selectedType, formData).then(response => {
        if (response.status === 200) {
          this.results = response.data.data.import

          if (this.results.total && this.results.total > 0) {
            if (this.results.success === 0) {
              this.toastDanger('Erro', 'Nenhum usuário criado')
            } else if (this.results.success === this.results.total) {
              this.toastSuccess('Sucesso', 'Todos os usuários foram criados')
            } else {
              this.toastDanger('Erro', `Alguns usuários não foram criados. ${this.results.success}/${this.results.total} Usuários Criados`)
            }
          } else {
            this.toastSuccess('Arquivo enviado!', '')
          }
        }
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: this.getResponseErrorMessage(error.response.data),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
